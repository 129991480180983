import React from "react"
import { Helmet } from "react-helmet"
import Layout from "src/components/common/Layout"
import SEO from "src/components/common/SEO"
import HeroSection from "src/components/page/widget/HeroSection"

const WatchPage = () => {
  return (
    <>
      <SEO title="Fubo Sports Network" />
      <HeroSection />
    </>
  )
}

export default WatchPage
