import React, { useEffect, useState } from "react"
import styled from "styled-components"

import VideoPlayer from "src/components/common/VideoPlayer"

import {
  liveStreamSrc,
  liveStreamType,
  springServeVast,
} from "src/modules/constants"

import FSNCanadaSlate from "/src/assets/images/FSN_Canada_Slate.jpg"
import GlobalSlate from "/src/assets/images/Global_Slate.jpg"
import WidgetCTA from "src/components/page/widget/WidgetCTA"

const videoJSOptions = {
  autoplay: true,
  controls: true,
  fill: true,
  liveui: true,
  responsive: true,

  sources: [
    {
      src: liveStreamSrc,
      type: liveStreamType,
    },
  ],
}

const imaOptions = {
  adTagUrl: springServeVast,
  showCountdown: true,
  showControlsForJSAds: true,
  adsRenderingSettings: {
    uiElements: ["countdown", "adAttribution"],
  },
}

// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   variableWidth: true,
//   responsive: [
//     {
//       breakpoint: 600,
//       settings: {
//         arrows: false,
//         centerMode: true,
//         slidesToShow: 2,
//       },
//     },
//   ],
// }

const HeroSection = () => {
  // Check geolocation for correct stream
  const [currentCountry, setCurrentCountry] = useState("")

  useEffect(() => {
    if (window && window.location.search.includes("spoofgeo")) {
      const parameters = new URLSearchParams(window.location.search)
      setCurrentCountry(parameters.get("spoofgeo"))
    } else {
      const locationApi = "https://dev-api.fubo.tv/v3/location"
      fetch(locationApi, {
        method: "GET",
      })
        .then(res => res.json())
        .then(response => {
          setCurrentCountry(response.country_code2)
          if (window)
            window["fuboLocationData"] = {
              country_code: response.country_code2,
              country_name: response.country_name,
            }
        })
        .catch(error => console.log(error))
    }
  }, [])

  return (
    <PageWrapper>
      {currentCountry === "US" || currentCountry === "USA" ? (
        <>
          <VideoPlayer {...videoJSOptions} imaOptions={imaOptions} />
        </>
      ) : currentCountry === "CA" || currentCountry === "CAN" ? (
        <VideoSlate>
          <img src={FSNCanadaSlate} alt="Sign up to watch at fubo.tv" />
        </VideoSlate>
      ) : (
        currentCountry !== "" && (
          <VideoSlate>
            <img
              src={GlobalSlate}
              alt="This Content is Unavailable. You are outside of the region where this is available."
            />
          </VideoSlate>
        )
      )}
      <WidgetCTA />
    </PageWrapper>
  )
}

export default HeroSection

const PageWrapper = styled.div`
  ${"" /* background-color: #242424; */}
  color: #242424;
  margin: 0;

  padding: 0px;
  max-width: 100%;
  width: 688px;
  height: 387.824px;
  position: relative;

  .videoPlayerContainer {
    margin: 0;
  }

  .heroCtaWrapper {
    width: auto;
    margin: 0;
  }
`

const VideoSlate = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 1300px) {
    max-width: 900px;
  }
  div.blank-slate {
    width: 100%;
    height: 0;
    padding-bottom: calc(100% * 9 / 16);
  }
  img {
    width: 100%;
  }
`
